import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { compose } from 'recompose';

import Product from '../product';
import Table from '../table';
import ActionCard from '../action-card';
import ButtonWithIcon from '../button-with-icon';
import { pickOrders } from '../../modules/with-permission';
import styles from './styles.module.css';

const splitLicenseNumberIntoPieces = (licensePlate) => {
  const splitNumber = licensePlate.match(/[a-z]+|[^a-z]+/gi);
  return splitNumber.join(' ');
};

const OrderDetails = ({
  id,
  number,
  status,
  products,
  delivery_from: deliveryFrom,
  delivery_to: deliveryTo,
  real_delivery_time: realDeliveryTime,
  real_loading_time: realLoadingTime,
  planned_loading_time: expectedLoadDate,
  note_driver: noteDriver,
  vehicle,
  driver,
  trip,
  carrier,
  t,
  withBackButton,
  withPickOrderButton,
  isPickOrderButtonDisabled,
  onPickOrderButtonClick,
  withRefuseOrderButton,
  onRefuseOrderButtonClick,
  isRefuseOrderButtonDisabled,
  onBackButtonClick,
  shouldShowLessInfo,
  pageTitle,
  kpi_proposes,
  isAdmin,
  isPlanner,
  force_sent_to_shared,
}) => {
  const warehouse = _.get(trip, 'warehouse', undefined);
  const clientAddress = _.get(trip, 'client_address', undefined);
  const distance = _.get(trip, 'distance', undefined);
  const refuseButton = (
    <ButtonWithIcon
      disabled={isRefuseOrderButtonDisabled}
      title={t('orders.refuse')}
      height="100%"
      icon="/assets/error-icon-light.png"
      width="150px"
      className="float-right danger-button right-aligned"
      onClick={() => onRefuseOrderButtonClick(id)}
    />
  );

  const backButton = (
    <ButtonWithIcon
      height="100%"
      icon="/assets/back-icon.png"
      width="50px"
      className="float-right back-button"
      onClick={onBackButtonClick}
    />
  );

  return (
    <>
      <ActionCard
        title={pageTitle || t('orders.orderDetails')}
        actionButtonLabel={t('orders.pick')}
        withActionButton={withPickOrderButton}
        onActionButtonClick={() => onPickOrderButtonClick(id)}
        actionButtonIcon="/assets/success-icon-light.png"
        actionButtonDisabled={isPickOrderButtonDisabled}
        actionButtonRequiredPermission={pickOrders}
        headerChildren={
          [
            ...(withBackButton ? [backButton] : []),
            ...(withRefuseOrderButton ? [refuseButton] : []),
          ]
        }
      >

        <Table isEmpty={false}>
          <tbody>
            {
              clientAddress && _.get(clientAddress, 'client.name', undefined)
              && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('clients.name')}</td>
                <td className="align-middle">{clientAddress.client.name}</td>
              </tr>
              )
            }
            <tr>
              <td className={`${styles.tableTitle} align-middle`}>{t('orders.orderNumber')}</td>
              <td className="align-middle">{number}</td>
            </tr>
            <tr>
              <td className={`${styles.tableTitle} align-middle`}>{t('orders.status')}</td>
              <td className="align-middle">{status.name}</td>
            </tr>
            {
            !shouldShowLessInfo && (
            <tr className="productLine">
              <td className={`${styles.tableTitle} productLabel`}>{t('orders.products')}</td>
              <td className="p-0 detailsBlock">
                <Table className={`${styles.innerTable} mb-0 align-middle`} isEmpty={false}>
                  <tbody>
                    <tr className={`${styles.header} align-middle hideTr`}>
                      <td className={`${styles.header} align-middle`}>{t('orders.productNumber')}</td>
                      <td className={`${styles.header} align-middle`}>{t('orders.product')}</td>
                      <td className={`${styles.header} align-middle`}>{t('orders.productQuantity')}</td>
                      <td className={`${styles.header} align-middle`}>{t('orders.uom')}</td>
                    </tr>
                    {
                        _.map(products, (product, index) => (
                          <Fragment key={index}>
                            <tr className={`${styles.header} align-middle showTr`}>
                              <td className={`${styles.header} align-middle`}>{t('orders.productNumber')}</td>
                              <td className={`${styles.header} align-middle`}>{t('orders.product')}</td>
                              <td className={`${styles.header} align-middle`}>{t('orders.productQuantity')}</td>
                              <td className={`${styles.header} align-middle`}>{t('orders.uom')}</td>
                            </tr>
                            <Product
                              key={product.number}
                              {...product}
                            />
                          </Fragment>
                        ))
                    }
                  </tbody>
                </Table>
              </td>
            </tr>
            )
            }
            {
              warehouse && !shouldShowLessInfo && (
                <tr>
                  <td className={`${styles.tableTitle} align-middle`}>{t('delivery.address.start')}</td>
                  <td className="align-middle">{`${warehouse.name}, ${warehouse.address}`}</td>
                </tr>
              )
            }
            {
              clientAddress
              && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('delivery.address.end')}</td>
                <td className="align-middle">{clientAddress.address}</td>
              </tr>
              )
            }
            {
              distance
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>
                  {t('delivery.distance')}
                </td>
                <td className="align-middle">{`${distance} km`}</td>
              </tr>
              )
            }
            {
              expectedLoadDate
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('delivery.expectedLoadTime')}</td>
                <td className="align-middle">{moment(expectedLoadDate).format('LLLL')}</td>
              </tr>
              )
            }

            {
              deliveryFrom && deliveryTo
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('delivery.expectedUnloadTime')}</td>
                <td className="align-middle">
                  {`${moment(deliveryFrom).format('LLLL')} - ${moment(deliveryTo).format('LLLL')}`}
                </td>
              </tr>
              )
            }

            {
              realLoadingTime
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('delivery.realLoadingTime')}</td>
                <td className="align-middle">{moment(realLoadingTime).format('LLLL')}</td>
              </tr>
              )
            }
            {
              realDeliveryTime
              && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('delivery.realDeliveryTime')}</td>
                <td className="align-middle">{moment(realDeliveryTime).format('LLLL')}</td>
              </tr>
              )
            }
            {
              carrier
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('kpi.carrier')}</td>
                <td className="align-middle">{carrier.name}</td>
              </tr>
              )
            }
            {
              driver && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>{t('driver.contact')}</td>
                <td className="align-middle">
                  {`${driver.name} (tel. ${driver.phone})`}
                </td>
              </tr>
              )
            }
            {
              vehicle && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>
                  {` ${t('nr.tractor')} & ${t('nr.trailer')} `}
                </td>
                <td className={`${styles.licensePlate} align-middle`}>
                  {splitLicenseNumberIntoPieces(vehicle.number_plate)}
                  {' - '}
                  {splitLicenseNumberIntoPieces(vehicle.number_plate_trailer)}
                </td>
              </tr>
              )
            }
            {
              noteDriver
              && !shouldShowLessInfo && (
              <tr>
                <td className={`${styles.tableTitle} align-middle`}>
                  {t('delivery.noteDriver')}
                </td>
                <td className="align-middle">{`${noteDriver}`}</td>
              </tr>
              )
            }
            {
              (isAdmin || isPlanner) && kpi_proposes && (
                <tr className="productLine">
                  <td className={`${styles.tableTitle} productLabel`}>{t('orders.kpiProposes')}</td>
                  <td className="p-0 detailsBlock">
                    <Table className={`${styles.innerTable} mb-0 align-middle`} isEmpty={false}>
                      <tbody>
                        <tr className={`${styles.header} align-middle hideTr`}>
                          <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.carrier')}</td>
                          <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.status')}</td>
                          <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.date')}</td>
                        </tr>
                        {
                            _.map(kpi_proposes, (kpi_propose) => (
                              <Fragment key={kpi_propose.id}>
                                <tr className={`${styles.header} align-middle showTr`}>
                                  <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.carrier')}</td>
                                  <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.status')}</td>
                                  <td className={`${styles.header} align-middle`}>{t('orders.kpiPropose.date')}</td>
                                </tr>
                                <tr>
                                  <td>{kpi_propose.carrier.name}</td>
                                  <td>{kpi_propose.status}</td>
                                  <td>
                                    {kpi_propose.status === 'REFUSED' ? kpi_propose.updated_at : kpi_propose.expires_at}
                                  </td>
                                </tr>
                              </Fragment>
                            ))
                        }
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )
            }
            {
              (isAdmin || isPlanner) && force_sent_to_shared && (
                <tr>
                  {t('orders.force_sent_to_shared')}
                </tr>
              )
            }
          </tbody>
        </Table>
      </ActionCard>
    </>
  );
};

OrderDetails.propTypes = {
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  vehicle: PropTypes.object,
  uom: PropTypes.string.isRequired,
  warehouse: PropTypes.object,
  client_address: PropTypes.object,
  driver: PropTypes.object,
  t: PropTypes.func.isRequired,
  products: PropTypes.array,
  isPickOrderButtonDisabled: PropTypes.bool,
  withPickOrderButton: PropTypes.bool,
  withBackButton: PropTypes.bool,
  onPickOrderButtonClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  withRefuseOrderButton: PropTypes.bool,
  onRefuseOrderButtonClick: PropTypes.func,
  isRefuseOrderButtonDisabled: PropTypes.bool,
  planned_delivery_time: PropTypes.string,
  planned_loading_time: PropTypes.string,
  note_driver: PropTypes.string,
  delivery_from: PropTypes.string,
  delivery_to: PropTypes.string,
  real_delivery_time: PropTypes.string,
  real_loading_time: PropTypes.string,
  trip: PropTypes.object,
  carrier: PropTypes.object,
  shouldShowLessInfo: PropTypes.bool,
  pageTitle: PropTypes.string,
  kpi_proposes: PropTypes.array,
  isAdmin: PropTypes.bool,
  isPlanner: PropTypes.bool,
  force_sent_to_shared: PropTypes.bool,
};

OrderDetails.defaultProps = {
  pageTitle: null,
  products: [],
  vehicle: null,
  warehouse: null,
  client_address: null,
  driver: null,
  withPickOrderButton: false,
  withBackButton: false,
  onBackButtonClick: () => {},
  onPickOrderButtonClick: () => {},
  isPickOrderButtonDisabled: false,
  withRefuseOrderButton: false,
  onRefuseOrderButtonClick: () => {},
  isRefuseOrderButtonDisabled: false,
  planned_loading_time: null,
  planned_delivery_time: null,
  delivery_from: null,
  delivery_to: null,
  real_delivery_time: null,
  real_loading_time: null,
  trip: null,
  carrier: null,
  shouldShowLessInfo: false,
  note_driver: null,
  kpi_proposes: [],
  isAdmin: false,
  isPlanner: false,
  force_sent_to_shared: false,
};

export default compose(withTranslation())(OrderDetails);
